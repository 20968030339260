import React, {useState} from "react";
import ReactSimplyCarousel from "react-simply-carousel";
import "./Profile.scss";

function Profile(props) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    let j = 0;

    const {photos, name, age, bio} = props;

    return (
        <div className="profile-container">
            <div className="carousel-container">
                <div className="photos-container">
                    <ReactSimplyCarousel
                        activeSlideIndex={activeSlideIndex}
                        onRequestChange={setActiveSlideIndex}
                        itemsToShow={1}
                        itemsToScroll={1}
                        forwardBtnProps={{
                            style: {
                                background: "transparent",
                                border: "none",
                                position: "absolute",
                                right: "12px",
                                paddingLeft: "30%",
                                paddingRight: "20px",
                                height: "100%",
                                zIndex: 1,
                                cursor: "pointer",
                                "&:focus": {
                                    outline:0
                                }
                        },
                            children: <div className="arrow right"></div>,
                        }}
                        backwardBtnProps={{
                            style: {
                                background: "transparent",
                                border: "none",
                                position: "absolute",
                                left: "12px",
                                paddingLeft: "20px",
                                paddingRight: "30%",
                                height: "100%",
                                zIndex: 1,
                                cursor: "pointer"
                            },
                            children: <div className="arrow left"></div>,
                        }}
                        speed={1}
                        easing="linear"
                    >
                        {photos.map(photo => {
                            return <div style={{
                                width: "450px",
                                maxWidth: "86vw",
                                paddingBottom: "15%",
                                // height: 600,
                                backgroundImage: "url(" + photo + ")",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                borderRadius: "0.5rem"
                            }}
                            key={"profile-key-" + j++}>
                            </div>
                        })}
                    </ReactSimplyCarousel>
                </div>
            </div>
            <h3 className="fat-font">{name}</h3>
            <span className="age">, &nbsp;{age}</span>
            <div className="other-details">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"></path>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"></path>
                </svg>
                <span>
                    Less than a mile away
                </span>
            </div>
            <div className="other-details">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"></path>
                </svg>
                <span>
                    University College Cork
                </span>
            </div>
            <p className="bio fat-font">
                {bio}
            </p>
        </div>
    );
}

export default Profile;
