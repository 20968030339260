import React from "react";
import './Thanks.scss';
import {Link} from "react-router-dom";

function Thanks() {
    return (
        <div className="thanks-container">
            <div className="breadcrumbs">
                <Link to="/">
                    Home
                </Link>
                <span className="separator">
                    /
                </span>
                <span className="current-page">
                    Form submitted
                </span>
            </div>
            <h2>Thanks!</h2>
            <p>We've received your email and we'll contact you shortly.</p>
            <p>Normally it takes less than 2h for us to contact you, but during periods of high demand it may take up to 2 days.</p>
            <br/>
            <br/>
            <br/>
            <Link to="/" className="thanks-link">
                Back to homepage
            </Link>
        </div>
    );
}

export default Thanks;
