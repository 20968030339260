import React, {useEffect} from "react";
import logo from './logo.png';
import './App.scss';
import {
    Routes,
    Route,
    Link,
    useLocation
} from "react-router-dom";
import Homepage from "./features/homepage/homepage";
import Menu from "./features/menu/menu";
import Thanks from "./features/thanks/thanks";
import Blog from "./features/blog/blog";
import Report from "./features/report/report";
import Checkout from "./features/checkout/checkout";
import {CONTACT_EMAIL, PRODUCT_NAME} from "./utils/constants";

const fbSend = contentsID => {
    window.fbq && window.fbq('track', 'ViewContent', {
        content_ids: contentsID
    });
    fetch(`/fb-conversions-api?contentsID=${contentsID}&eventType=ViewContent`);
}

function App() {

    const location = useLocation();

    useEffect(() => {
        if(location.pathname.indexOf("/report/sample") === 0) {
            fbSend("sample-report");
        } else if(location.pathname.indexOf("/checkout") === 0) {
            fbSend("checkout");
        }
    }, [location]);


    useEffect(() => {
        if(window.location.hash) {
            const eId = window.location.hash.substring(1);
            setTimeout(() => document.getElementById(eId)?.scrollIntoView(), 500);
        }
    }, []);

    return (
        <div className="app">
            <header className="app-header">
                <div className="logo-container">
                    <Link to="/">
                        <img src={logo} className="app-logo" alt="logo"/>
                    </Link>
                </div>
                <div className="menu-positioner">
                    <Menu/>
                </div>
            </header>
            <main>
                <Routes>
                    <Route path="/" element={<Homepage/>}/>
                    <Route path="/blog/ai" element={<Blog/>}/>
                    <Route path="/form-submitted" element={<Thanks/>}/>
                    <Route path="/report/sample" element={<Report/>}/>
                    <Route path="/checkout" element={<Checkout/>}/>
                </Routes>
            </main>
            <footer>
                <div className="footer-h2">Discover what the world thinks of you</div>
                <ul>
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="/#product">{PRODUCT_NAME}</a>
                    </li>
                    <li>
                        <a href="/report/sample">
                            Sample report
                        </a>
                    </li>
                    <li>
                        <a href={"mailto:" + CONTACT_EMAIL}>Contact us</a>
                    </li>
                </ul>
                <div className="footer-copyright">
                    <span>Copyright © 2023 <a href="/">Swipeability</a></span>
                </div>
            </footer>
        </div>
    );
}

export default App;
