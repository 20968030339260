import React from "react";
import './Blog.scss';
import {
    Routes,
    Route,
    Link
} from "react-router-dom";
import yourNewMatch from "../../assets/images/your_new_match.png";
import firstPrompt from "../../assets/images/first_prompt.png";
import converaj1 from "../../assets/images/converaj1.png";
import converaj2 from "../../assets/images/converaj2.png";
import converaj2_1 from "../../assets/images/converaj2_1.png";
import converaj3_1 from "../../assets/images/consersaj3_1.png";
import converaj4_1 from "../../assets/images/conversaj4.png";
import converaj5_1 from "../../assets/images/conversaj5.png";
import converaj6_1 from "../../assets/images/conversaj6.png";

function Blog() {
    return (
        <>
            <div className="blog-hero-img">
                <img src={yourNewMatch} alt="chatbot"/>
                <span className="article-img-label">Your new Tinder match (by DALL·E 2)</span>
            </div>
            <div className="blog-breadcrumbs breadcrumbs">
                <Link to="/">
                    Home
                </Link>
                <span className="separator">
                    /
                </span>
                <span className="current-page">
                    Blog
                </span>
            </div>
            <div className="blog-article">
                <h1>AI augmented charm, a turning point in online dating</h1>
                <time dateTime="2022-12-14T20:00:00Z">Dec 19, 2022</time>
                <div className="article-content">
                    <p>
                        Today I am going to talk about online dating and ChatGPT.
                    </p>
                    <p>
                        For those who are not familiar with ChatGPT, it is an AI chatbot specialized in dialogue.
                        Simply give it a prompt and it will
                        respond with remarkable human-like intelligence and character.
                    </p>
                    <p>
                        This AI is relevant because a challenge with online dating is finding what to say. Make it fun,
                        easy to understand and reply
                        to, all while being flirtatious. Humour is key, but it has to fit the context.
                    </p>
                    <p>
                        What I discovered while playing with ChatGPT was that it can create dialogue so well with
                        matches on Tinder that
                        people will inevitably start using it to augment their own charisma.
                        {/*once we all realise*/}
                        {/*what is going one it eventually will*/}
                        {/*change online dating for good.*/}
                    </p>
                    <div className="article-image">
                        <img src={firstPrompt} alt="first prompt screenshot"/>
                        <span className="article-img-label">Using ChatGPT for dialogue with a Tinder match</span>
                    </div>
                    <h2>Example of a conversation</h2>
                    <p>
                        Let's see it in action.
                    </p>
                    <p>
                        I tried it with one of my matches out of curiosity and I was blown away by how well it had
                        managed
                        to flirt with her with minimal help from me.
                    </p>
                    <p>
                        Below you can find the conversation, my contribution was the opener, everything else is ChatGPT
                        conversing with my match. And yes she did look like
                        Angelina Jolie.
                        <br/>Note: the Tinder match is not a native English speaker.

                    </p>
                    <div className="article-image">
                        <img src={converaj1} alt="chatbot assisted tinder conversation screenshot"/>
                        <img src={converaj2} alt="chatbot assisted tinder conversation #2 screenshot"/>
                        <span className="article-img-label">Tinder exchange between the chatbot and match</span>
                    </div>
                    <p>
                        I was surprised by the chatbot's ability to reference previous messages, such as mentioning Brad
                        Pitt, but
                        even more so how well it knew how to use the moment to flirt with her.
                    </p>
                    <p>
                        It knew how to spice up the conversation in a seemingly natural way by adding comments such as:
                        <br/>
                        <br/>• <i>He doesn't stand a chance against my charm and wit.</i>
                        <br/>• <i>I am a master at avoiding paparazzi</i>
                        <br/>• <i>Do you have any special talents or abilities that you want to share?</i>
                    </p>
                    <p>
                        While it may not be perfect, it's certainly above your average Tinder conversation.
                    </p>
                    <h2>What can the bot actually do?</h2>
                    <p>
                        Alright, so what exactly can this bot do? We can't just feed it a dating profile and it will
                        start giving out replies, thankfully we're not there yet (although this dystopian reality is
                        uncomfortably close).
                    </p>
                    <p>
                        To unlock the chatbot's flirtatious side, we simply need to provide it with a prompt that
                        includes some relevant information and give it the context (that this is a Tinder conversation).
                        The chatbot will then respond with its best attempt.
                    </p>
                    <div className="article-image">
                        <img src={converaj5_1} alt="chatbot assisted tinder conversation #5 screenshot"/>
                        <span className="article-img-label">AI assisted opener.</span>
                    </div>
                    <h2>Opener</h2>
                    <p>
                        Let's say we have a new match, we don't know how to open because we don't want to throw in a
                        generic pickup line, we want to use something from her profile.
                    </p>
                    <p>
                        In the first example we used the
                        fact that she looks like Angelina Jolie as a starting point and we saw what the AI came up with.
                        But that opener was my own idea, not the bot's, however we can easily get it to give us openers.
                    </p>
                    <p>
                        Lets see an example.<br/>
                        Say we match with a profile that has very little information but we notice that she is dressed
                        in black clothes in all her photos. Let's see what the bot comes up with.
                    </p>
                    <div className="article-image">
                        <img src={converaj2_1} alt="chatbot assisted tinder conversation example"/>
                        <span className="article-img-label">How we got an opener from the bot</span>
                    </div>
                    <p>
                        You will notice that I didn't ask the bot for an opener directly, but I mocked up a fake Tinder
                        conversation in which I added the relevant detail (that she always dresses in black) and asked
                        it to continue with a new hilarious reply.
                    </p>
                    <p>
                        I found this is the best way to get consistent results from the bot. Just write a bit of
                        dialogue and ask to it continue.
                    </p>
                    <p>
                        What the bot came up with is pretty usable, it's personalised to our match and it's funny. Not
                        bad!<br/>
                        Alright, let's try another example.
                    </p>
                    <div className="article-image">
                        <img src={converaj3_1} alt="chatbot assisted tinder conversation opener example"/>
                        <span className="article-img-label">Another opener generated by ChatGPT</span>
                    </div>
                    <p>
                        In the screenshot shown above, we used the bot to obtain an opener for a profile that only
                        featured a selfie with a small Christmas tree in the background.
                    </p>
                    <h2>After the opener</h2>
                    <p>
                        Using the same method as above, we can get the bot to give us replies by just feeding it the
                        conversation as it unfolds.<br/>
                        So for the previous "tiny tree" opener, we simply added our match's reply and them the chatbot
                        gave us a decent reply.
                    </p>
                    <div className="article-image">
                        <img src={converaj6_1} alt="chatbot assisted tinder conversation after opener"/>
                    </div>
                    <h2>The chatbot flirts, not just communicates</h2>
                    <p>
                        Notice that the bot knows how to flirt if we tell it this is a Tinder conversation, it does spin
                        it's output to fit the situation.
                    </p>
                    <p>
                        When writing the prompt, if we omit the fact that it's a Tinder conversation, and we replace it
                        with different contexts, like a conversation with a new neighbor or boss, the chatbot will adapt
                        the response to the situation.
                    </p>
                    <p>
                        All in all, this is a powerful tool for people trying to create entertaining online
                        conversations.
                    </p>
                    <h2>What does this mean for online dating going forward?</h2>
                    <p>
                        It means online dating will change forever. It will take some time but we'll soon be
                        faced with the reality that we are talking with people being assisted by chatbots, and
                        maybe even matching with profiles with fake
                        photos generated by AI.
                    </p>
                    <p>
                        The whole online dating ecosystem is going to be upended and we will
                        need a new approach.
                    </p>
                    <p>
                        Are we all going to start going to bars and dancing classes again just to meet people?<br/>
                        I don't think so, the benefits of doing all this from your phone will keep people invested into
                        online dating apps.
                    </p>
                    <p>
                        Maybe having a phone call early on will become common? Who knows. But we will adapt.
                    </p>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        </>
    );
}

export default Blog;
