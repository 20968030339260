import React, {useEffect} from "react";
import './Testimonials.scss';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import testimonialPic1 from "../../assets/images/testimonials/1.jpg";
import testimonialPic2 from "../../assets/images/testimonials/2.jpg";
import testimonialPic3 from "../../assets/images/testimonials/3.jpg";

function Testimonials() {

    return (
        <div className="testimonials-container" id="testimonials">
            <h3 className="testimonials-title">Testimonials</h3>
            <Carousel showArrows={true} showThumbs={false} autoPlay={true} infiniteLoop={true} interval={9000}>
                <div className="testimonials-slide">
                    <p className="testimonials-photo" style={{backgroundImage: `url(${testimonialPic2})`}}>
                    </p>
                    <h4 className="testimonials-name">Christian T.</h4>
                    <p className="testimonial-text">
                        "Having the right photos drastically changed not only the number of matches I get, but also
                        how enthusiastic they are to be talking to me."
                    </p>
                </div>
                <div className="testimonials-slide">
                    <p className="testimonials-photo" style={{backgroundImage: `url(${testimonialPic1})`}}>
                    </p>
                    <h4 className="testimonials-name">Andrew P.</h4>
                    <p className="testimonial-text">
                        "A VERY useful tool for tinkering with you dating profile!"
                    </p>
                </div>
                <div className="testimonials-slide">
                    <p className="testimonials-photo" style={{backgroundImage: `url(${testimonialPic3})`}}>
                    </p>
                    <h4 className="testimonials-name">Marius G.</h4>
                    <p className="testimonial-text">
                        "This has addressed a major blind spot I had in online dating and helped me improve things I
                        simply assumed were set in stone."
                    </p>
                </div>
            </Carousel>
        </div>
    );
}

export default Testimonials;
