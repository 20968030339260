import React from "react";
import './Homepage.scss';
import heroImg from "../../assets/images/hero.png";
import Feedback from "../product/feedback";
import {Helmet} from "react-helmet";

function Homepage() {
    return (
        <>
            <Helmet>
                <title>Swipeability</title>
            </Helmet>
            <div className="hero">
                <h1>Discover what the world thinks of you</h1>
                <h3>Maximize your opportunity in the age of online dating.</h3>
            </div>
            <div className="hero-img">
                <img src={heroImg} alt="demo"/>
            </div>
            <div className="our-product">
                <div className="our-product-hero">
                    <h2>Our product</h2>
                    <h3>1,000+ customers. 5-star average rating. <br/> &lt; 0.2% refund rate.</h3>
                </div>
                <Feedback/>
            </div>
        </>
    );
}

export default Homepage;
