import React, {useState, createRef} from "react";
import {CONTACT_EMAIL} from "../../utils/constants";

import "./Menu.scss";

export default function Menu(props) {
    const [isActive, setIsActive] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showContactUs, setShowContactUs] = useState(false);
    const email = createRef();

    const handleSubmit = (event) => {
        const emailEl = email?.current;
        event.preventDefault();

        if (!emailEl?.disabled && (!emailEl.value || (emailEl.checkValidity && !emailEl.checkValidity()))) {
            emailEl.style.animation = "shake 0.5s";
            setTimeout(() => {
                emailEl.style.animation = "";
            }, 500);
        } else {
            emailEl.value = "...";
            event.target.disabled = true;
            setTimeout(() => {
                emailEl.disabled = true;
                emailEl.value = "";
                emailEl.placeholder = "Check your inbox for the login link";
            }, 800);
        }
    };


    return (
        <div className={"menu-container " + (isActive ? "menu-active" : "")}>
            {isActive && <div className="menu-overlay "></div>}
            <svg className="svg-burger">
                <defs>
                    <filter id="gooeyness">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="2.2" result="blur"/>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10"
                                       result="gooeyness"/>
                        <feComposite in="SourceGraphic" in2="gooeyness" operator="atop"/>
                    </filter>
                </defs>
            </svg>
            <div className={"plate plate4 " + (isActive ? "active" : "")}
                 onClick={() => setIsActive(!isActive)}>
                <svg className="burger" version="1.1" height="100" width="100" viewBox="0 0 100 100">
                    <path className="menu-line menu-line1" d="M 50,35 H 30"/>
                    <path className="menu-line menu-line2" d="M 50,35 H 70"/>
                    <path className="menu-line menu-line3" d="M 50,50 H 30"/>
                    <path className="menu-line menu-line4" d="M 50,50 H 70"/>
                    <path className="menu-line menu-line5" d="M 50,65 H 30"/>
                    <path className="menu-line menu-line6" d="M 50,65 H 70"/>
                </svg>
                <svg className="menu-x" version="1.1" height="100" width="100" viewBox="0 0 100 100">
                    <path className="menu-line" d="M 34,32 L 66,68"/>
                    <path className="menu-line" d="M 66,32 L 34,68"/>
                </svg>
            </div>
            {isActive && <ul className="menu-items">
                <li>
                    <h4 onClick={() => setShowLogin(!showLogin)}>Login</h4>
                    {showLogin && <p className="menu-subtext">
                        <input
                            type="email"
                            placeholder="Enter your email"
                            ref={email}
                        />
                        <input
                            type="button"
                            value="Send login link"
                            onClick={handleSubmit}
                        />
                    </p>}
                </li>
                <li>
                    <h4>
                        <a href="/#testimonials" onClick={() => setIsActive(false)}>
                            Testimonials
                        </a>
                    </h4>
                </li>
                <li>
                    <h4>
                        <a href="/report/sample" onClick={() => setIsActive(false)}>
                            Sample report
                        </a>
                    </h4>
                </li>
                <li>
                    <h4 onClick={() => setShowContactUs(!showContactUs)}>Contact us</h4>
                    {showContactUs && <a className="menu-subtext" href={"mailto:" + CONTACT_EMAIL}>
                        <u>{CONTACT_EMAIL}</u>
                    </a>}
                </li>
            </ul>}
        </div>
    );
}
