import React, {useState, useEffect} from "react";
import './Report.scss';

import img1 from "../../assets/images/sample-report-photos/1.jpg";
import img3 from "../../assets/images/sample-report-photos/3.jpg";
import img4 from "../../assets/images/sample-report-photos/4.jpg";
import img5 from "../../assets/images/sample-report-photos/5.jpg";
import img7 from "../../assets/images/sample-report-photos/7.jpg";
import img8 from "../../assets/images/sample-report-photos/8.jpg";
import img9 from "../../assets/images/sample-report-photos/9.jpg";
import img11 from "../../assets/images/sample-report-photos/11.png";
import img15 from "../../assets/images/sample-report-photos/15.png";
import img16 from "../../assets/images/sample-report-photos/16.jpg";
import img17 from "../../assets/images/sample-report-photos/17.jpg";

import Profile from "../profile/profile";
import {Helmet} from "react-helmet";

const BIG_O = ["attractive", "smart", "trustworthy"];
const BIG_O_BIO = ["score"];

const BIOS = [
    'The last time I was someone’s "type" was when I donated blood.',
    "Can't wait to match, exchange one message, and then never talk again!",
    "Striving to make people laugh, taking spontaneous road trips, and chasing sunsets. Swipe right if you love to hike and can handle a bit of sarcasm."
];

const barBigO = (type, value) => {
    const perc = (value * 100) / 5;
    return <li key={"bigo-type-" + type + value}>
        <div className={"bigo-bar-fauxull " + " bigo-type-" + type}></div>
        <div className={"bigo-bar " + " bigo-type-" + type} style={{width: perc + "%"}}></div>
        <div className="bigo-bar-info">
            <span className="bigo-bar-info-type fat-font">{type}</span>
            <span className="fat-font">{value}</span>
        </div>
    </li>
}

const getPhotoData = (photo, values, notes, i, total) => {
    return <>
        <li className="data-list-item" key={"profile-data-" + i}>
            <div className="photo-container">
                <span className="counter">{i + "/" + total}</span>
                <p style={{backgroundImage: "url(" + photo + ")"}}></p>
            </div>
            <ul className="big-o-list">
                {barBigO(BIG_O[0], values[0])}
                {barBigO(BIG_O[1], values[1])}
                {barBigO(BIG_O[2], values[2])}
            </ul>
            <div className="notes">
                <h4>Notes that people added:</h4>
                {notes.map((n, i) => {
                    return <p className="note-container" key={"note-container-" + i}>
                        <span className="note" key={"note-key-" + i}>{n[0]}</span>
                        <span className="note-x fat-font">x</span>
                        <span className="note-times fat-font">{n[1]}</span>
                    </p>
                })}
            </div>
        </li>
    </>;
}

const getBioData = (bio, value, notes, i, total) => {
    return <li className="data-list-item" key={"profile-data-" + i}>
        <div className="bio-container">
            <span className="counter">{i + "/" + total}</span>
            <p>Bio: {bio}</p>
        </div>
        <ul className="big-o-list">
            {barBigO(BIG_O_BIO[0], value)}
        </ul>
        <div className="notes">
            <h4>Notes that people added:</h4>
            {notes.map((n, i) => {
                return <p className="note-container" key={"node-cont-" + i}>
                    <span className="note" key={"note-key-" + i}>{n[0]}</span>
                    <span className="note-x fat-font">x</span>
                    <span className="note-times fat-font">{n[1]}</span>
                </p>
            })}
        </div>
    </li>
}

const NOTES = {
    great_smile: "Great smile",
    bland: "Bland",
    smiling_too_much: "Smiling too much",
    you_seem_like_fun: "You seem like fun",
    prefer_non_selfies: "Prefer non-selfies",
    prefer_smile: "Prefer smiles",
    prefer_different_expression: "Prefer different expression",
    prefer_different_angle: "Prefer different angle",
    prefer_different_crop: "Prefer different crop",
    prefer_solo: "Prefer solo photo",
    prefer_to_see_face: "Prefer face is visible",
    funny: "Funny",
    edgy: "Edgy",
    cool: "Cool",
    dont_get_it: "Don't get it",
    prefer_more_serious: "Prefer more serious",
    prefer_more_humor: "Prefer more humor",
    unoriginal: "Unoriginal",
    negativistic: "Negativistic",
    charismatic: "Charismatic"
}


const SAMPLE_DATA = [
    {
        photo: img3,
        values: [2.3, 2.2, 2.8],
        notes: [
            [NOTES.prefer_non_selfies, 12],
            [NOTES.great_smile, 3],
            [NOTES.bland, 1]
        ]
    },
    {
        photo: img1,
        values: [4.4, 4.8, 4.5],
        notes: [
            [NOTES.great_smile, 14],
            [NOTES.you_seem_like_fun, 9],
            [NOTES.bland, 3],
            [NOTES.smiling_too_much, 2],

        ]
    },
    {
        photo: img4,
        values: [2.3, 2.1, 2.8],
        notes: [
            [NOTES.prefer_smile, 10],
            [NOTES.prefer_non_selfies, 6],
            [NOTES.bland, 5],
        ]
    },
    {
        photo: img5,
        values: [2.4, 3.1, 3.4],
        notes: [
            [NOTES.you_seem_like_fun, 6],
            [NOTES.smiling_too_much, 3]
        ]
    },
    {
        photo: img7,
        values: [1.8, 2.9, 3.3],
        notes: [
            [NOTES.prefer_different_expression, 5],
            [NOTES.you_seem_like_fun, 3],
            [NOTES.prefer_different_angle, 3],
        ]
    },
    {
        photo: img8,
        values: [3.0, 4.1, 3.9],
        notes: [
            [NOTES.great_smile, 10],
            [NOTES.prefer_solo, 10],
            [NOTES.you_seem_like_fun, 7],
        ]
    },
    {
        photo: img9,
        values: [2.8, 2.7, 3.5],
        notes: [
            [NOTES.prefer_non_selfies, 5],
            [NOTES.bland, 4],
        ]
    },
    {
        photo: img11,
        values: [4.5, 4.7, 4.4],
        notes: [
            [NOTES.you_seem_like_fun, 14],
            [NOTES.prefer_solo, 6],
            [NOTES.prefer_different_crop, 2],
            [NOTES.great_smile, 1],
        ]
    },
    {
        photo: img15,
        values: [1.3, 3.2, 1.9],
        notes: [
            [NOTES.prefer_to_see_face, 21],
            [NOTES.bland, 14],
            [NOTES.prefer_different_angle, 2],
        ]
    },
    {
        photo: img16,
        values: [4.5, 3.6, 3.9],
        notes: [
            [NOTES.you_seem_like_fun, 4],
            [NOTES.great_smile, 4],
            [NOTES.prefer_solo, 3],
            [NOTES.prefer_different_crop, 2]
        ]
    },
    {
        photo: img17,
        values: [4.8, 4.7, 4.5],
        notes: [
            [NOTES.great_smile, 15],
            [NOTES.you_seem_like_fun, 12],
            [NOTES.prefer_different_angle, 1],
        ]
    },
    {
        bio: BIOS[0],
        values: 4.4,
        notes: [
            [NOTES.funny, 18],
            [NOTES.cool, 15],
            [NOTES.you_seem_like_fun, 13],
            [NOTES.prefer_more_serious, 7],
            [NOTES.negativistic, 4],
            [NOTES.charismatic, 4],
        ]
    },
    {
        bio: BIOS[1],
        values: 1.6,
        notes: [
            [NOTES.negativistic, 17],
            [NOTES.prefer_more_serious, 4],
            [NOTES.edgy, 3],
        ]
    },,
    {
        bio: BIOS[2],
        values: 3.3,
        notes: [
            [NOTES.bland, 13],
            [NOTES.prefer_more_humor, 6],
            [NOTES.charismatic, 1],
            [NOTES.you_seem_like_fun, 1],
        ]
    }
]

function Report() {

    return (<>
            <Helmet>
                <title>Swipeability — Sample Report</title>
            </Helmet>

            <div className="report-container">
                <span className="sample-watermark">sample</span>
                <div className="best">
                    <div className="hero-text">
                        <h1>Your best dating profile</h1>
                        <p>The following combination contains your best images and bio.
                            <br/>
                            <br/>
                            ⬇⬇⬇
                        </p>
                    </div>
                    <Profile
                        photos={[img17, img1, img11]}
                        name="Kevin"
                        age="26"
                        bio={BIOS[0]}
                    />
                </div>
                <div className="the-data">
                    <h2>And here's the data</h2>
                    <p>These are your scores for the photos and bios you've submitted.</p>
                    <ul className="data-list">
                        {SAMPLE_DATA.map((sd, i) =>
                            sd.photo ?
                                getPhotoData(sd.photo, sd.values, sd.notes, i + 1, SAMPLE_DATA.length)
                                : getBioData(sd.bio, sd.values, sd.notes, i + 1, SAMPLE_DATA.length))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Report;
