import React, {useState} from "react";
import './Checkout.scss';
import profileFeed from "../../assets/images/profilefeedpixel.png";
import {Link} from "react-router-dom";
import AddressInput from "./addressInput";
import {PRODUCT_NAME} from "../../utils/constants";

const cartSVG = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" role="img" focusable="false"
                     aria-hidden="true" className="a8x1wuo _1fragem14 _1fragem30 _1fragem9e _1fragem9d">
    <circle cx="3.5" cy="11.9" r="0.3"></circle>
    <circle cx="10.5" cy="11.9" r="0.3"></circle>
    <path strokeLinecap="round" strokeLinejoin="round"
          d="M3.502 11.898h-.004v.005h.004v-.005Zm7 0h-.005v.005h.005v-.005ZM1.4 2.1h.865a.7.7 0 0 1 .676.516l1.818 6.668a.7.7 0 0 0 .676.516h5.218a.7.7 0 0 0 .68-.53l1.05-4.2a.7.7 0 0 0-.68-.87H3.4"></path>
</svg>;


function Checkout() {
    const [email, setEmail] = useState('unset');
    const errRef = React.createRef();

    const formSubmit = (email) => {

        // fb send
        const contentsID = "DatingProfileMaxxing";
        window.fbq && window.fbq('track', 'InitiateCheckout', {
            content_ids: contentsID
        });
        fetch(`/fb-conversions-api?contentsID=${contentsID}&eventType=InitiateCheckout`);

        errRef.current.innerText = ""
        fetch(`/billing-submit?email=${email}`);
        setTimeout(() => {
            errRef.current.innerText = "Server error. Try again later."
        }, 1000);
    };

    return (
        <div className="checkout-container">
            <div className="order-summary">
                <div className="order-summary-top">
                    <h3>{cartSVG} Order summary</h3>
                    <span className="order-summary-price"></span>
                </div>
                <div className="order-items">
                    <a className="order-item" href="/#product">
                        <div className="order-item-photo">
                            <img src={profileFeed}/>
                        </div>
                        <p className="order-item-name">{PRODUCT_NAME}</p>
                        <p className="order-item-price">$19.00</p>
                    </a>
                </div>
                <div className="order-total">
                    <div className="total-label">Total</div>
                    <div className="total-numeric">
                        <span className="order-currency">
                            USD
                        </span>
                        <span className="order-total-numeric">
                            $19.00
                        </span>
                    </div>
                </div>
            </div>
            <div className="order-fill-in">
                <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="separator">/</span>
                    Checkout
                    <span className="separator">/</span>
                    <span className="current-page">Billing</span>
                </div>
                <form className="order-fill-in-form">
                    <h3>
                        Email
                    </h3>
                    <input
                        type="email"
                        name="email-address"
                        placeholder="Email Address"
                        required={true}
                        onChange={(event) => {
                            setEmail(event.target.value);
                        }}
                    />
                    <h3>
                        Billing Address
                    </h3>
                    <AddressInput/>
                    <a href="javascript:void(0)" className="button button-cta" onClick={() => formSubmit(email)}>Next</a>
                    <span ref={errRef} className="error-checkout"></span>
                </form>
            </div>
        </div>
    );
}

export default Checkout;
