import React, {useState, useEffect} from "react";
import {Helmet} from "react-helmet";

function AddressInput() {

    // very hackish
    const [stripe, setStripe] = useState(null);

    window.onStripeJSLoad = () => {
        const stripe = window.Stripe(
            'pk_test_51N7y8oL5QtuyD7EzMST7SeHrIv5bYqym4X7KuxBWlfY1wmvCMjsorzYULZSipueYtCjxypE15GBU7h5c84h0nO9000SBe8wIQD'
        );
        setStripe(stripe);
    }

    useEffect(() => {
        if (stripe) {
            const elements = stripe.elements({
                appearance: {
                    theme: 'stripe'
                }
            });
            const addressElement = elements.create("address", {
                mode: "shipping",
            });
            addressElement.mount("#address-element");
        }
    }, [stripe]);

    return (
        <>
            <Helmet>
                <title>Swipeability — Checkout</title>
                <script src="https://js.stripe.com/v3/" onLoad="window.onStripeJSLoad()"></script>
            </Helmet>
            <div id="address-element">...</div>
        </>
    );
}

export default AddressInput;
