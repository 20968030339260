import React, {useState, useEffect} from "react";
import './Product.scss';
import profileFeed from "../../assets/images/profilefeedpixel.png";
import howitworks from "../../assets/images/howitworks.png";
import Testimonials from "../testimonials/testimonials";
import {PRODUCT_NAME} from "../../utils/constants";

function Feedback() {

    const [learnMore, setLearnMore] = useState(false);

    const getCTA = (v, showLearnMore) => {
        const text = v == 1 ? "Start getting better results" : "Start here"
        return (<div className="homepage-cta">
            {/*<Link type="button" className="button button-cta" to="/checkout">{text}</Link>*/}
            <a className="button button-cta" href="/checkout">{text}</a>
            {showLearnMore && <>
                <p>or</p>
                <span
                    className="underline-product cursor-pointer"
                    onClick={() => setLearnMore(!learnMore)}>
                            Learn more
                        </span>
            </>}
        </div>)
    };

    useEffect(() => {
        if (learnMore) {
            const contentsID = "learn-more";
            window.fbq && window.fbq('track', 'ViewContent', {
                content_ids: contentsID
            });
            fetch(`/fb-conversions-api?contentsID=${contentsID}&eventType=ViewContent`);
        }
    }, [learnMore]);



    return (
        <>
            <div className="hero-img" id="product">
                <img src={profileFeed} alt="demo"/>
            </div>
            <div className="product-description">
                <h1>{PRODUCT_NAME}</h1>
                <span className="price">$19.00</span>
                <span className="old-price">$39.00</span>
                <div className="product-description-text" >
                    <p>
                        In online dating your profile matters most.
                    </p>
                    <p>
                        But as humans, we are terrible at judging how we are perceived by others.
                    </p>
                    <p>
                        <span className="underline-product">{PRODUCT_NAME}</span> is a tool that
                        gives you game changing insights into your dating profile by revealing what your photos
                        and bio say about you, paving the way to a better profile and more matches.
                    </p>
                    {getCTA(1, true)}
                    <div className={"learn-more " + (learnMore && " show")}>
                        <div className="hero-img">
                            <img src={howitworks} alt="demo"/>
                        </div>
                        <h2>How does it work?</h2>
                        <p>
                            <br/>
                            <a className="underline-product" href="/report/sample">View a sample report →</a>
                            <br/>
                            <br/>
                        </p>
                        <p>
                            You upload up to 10 photos and 3 versions of bios, which we compile into a profile that gets
                            reviewed by 30 random people.<br/>
                        </p>
                        <p>
                            You will get back detailed feedback that will let you know which photos are the best and which bio is
                            the best. You then use this information to optimize your dating profile.
                            <br/>
                            <br/>
                        </p>
                        <p>
                        <span className="underline-product">
                            Who are the people who will give feedback on your profile?
                        </span><br/>
                            <br/>
                            They are regular people from all walks of life, but you select the gender, age range and
                            geographic location and we find them for you.
                        </p>
                        <p className="par-indent">
                            You can choose the <span className="underline-product">gender</span> of the people
                            that will see your photos and bios. You can choose between men and women.
                        </p>
                        <p className="par-indent">
                            Preferences differ with age, a 35 year
                            old woman is interested in different things than a 21 year old. Choosing the&nbsp;
                            <span className="underline-product">age range</span> that
                            you're interested in is crucial.
                        </p>
                        <p className="par-indent">
                            Some cultures are more conservative, others are more open, some places don't get sarcasm or
                            have a preference for certain aesthetics.
                            <br/>Local culture matters! This is why it's important to <span className="underline-product">specify the
                            country</span> where you are located in, because we want to show your profile to people who
                            are most relevant culturally to you.
                        </p>
                        <p>
                            <br/>
                            <span className="underline-product">Who is this for?</span><br/>
                            <br/>This is for you if:
                            <div className="par-indent">
                                You're not getting as many matches as you expect
                            </div>
                            <div className="par-indent">
                                You're not matching with the type of people you expect
                            </div>
                            <div className="par-indent">
                                You want to get more and better quality matches
                            </div>
                            <div className="par-indent">
                                Or you simply want to understand how other people see you
                            </div>
                        </p>


                        Sounds interesting?

                        {getCTA()}
                    </div>
                </div>
            </div>
            <div className="horizontal-separator"/>
            <Testimonials/>
        </>
    );
}

export default Feedback;
